import React from 'react';
import { Helmet } from 'react-helmet';

import Navbar from './Navbar';

type LayoutProps = {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Jeff Kwan Photography</title>
        <meta name="description" content="San Francisco Bay Area Wedding, Engagement, Portrait Photography" />
      </Helmet>
      <div className="font-sans">
        <Navbar />
        <main className='mt-5 md:container md:mx-auto'>
          {children}
        </main>
      </div>
    </>
  );
}
