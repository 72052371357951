import { Fragment } from 'react';
import { Link } from 'gatsby';
import { Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const Navbar = () => (
  <nav className="bg-white border-gray-200 border-b px-2 sm:px-4 py-3">
    <Menu>
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" className="flex items-center">
          <span className="font-bold tracking-wide uppercase text-xl">Jeff Kwan Photography</span>
        </Link>
        <Menu.Button className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none">
          {({ open }) => (
            <>
              <span className="sr-only">Menu</span>
              {open ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </>
          )}
        </Menu.Button>
        <div className="hidden w-full md:block md:w-auto">
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <Link className="py-1" to="/" activeClassName="font-bold">Portfolio</Link>
            </li>
            <li>
              <Link className="py-1" to="/about" activeClassName="font-bold">About</Link>
            </li>
            <li>
              <a href="https://jeffkwan.darkroom.tech" target="_blank" rel="noopener" className="py-1">Shop</a>
            </li>
            <li>
              <Link className="py-1" to="/contact" activeClassName="font-bold">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Mobile Menu */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="flex flex-col px-1 py-1">
          <Menu.Item>
            <Link className="py-1" to="/" activeClassName="font-bold">Portfolio</Link>
          </Menu.Item>
          <Menu.Item>
            <Link className="py-1" to="/about" activeClassName="font-bold">About</Link>
          </Menu.Item>
          <Menu.Item>
            <a href="https://jeffkwan.darkroom.tech" target="_blank" rel="noopener" className="py-1">Shop</a>
          </Menu.Item>
          <Menu.Item>
            <Link className="py-1" to="/contact" activeClassName="font-bold">Contact</Link>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  </nav>
);

export default Navbar;
